import { PDFViewer } from '@react-pdf/renderer';
import { Render } from './Render';
import { Client, Service } from '../types';

interface PdfRendererProps {
  isLoading: boolean;
  brojRacuna: string;
  datum: string;
  client: Client;
  services: Service[];
  tecaj: string;
}

export const PdfRenderer = ({ isLoading, datum, tecaj, brojRacuna, client, services }: PdfRendererProps) => {
  if (isLoading || !datum || !tecaj || !brojRacuna || !client || !services) {
    return null;
  }

  const tecajNumber = Number(tecaj);
  const totalSum = services.reduce((acc, service) => acc + (service.kolicina || 1) * service.basePrice, 0);
  const totalSumEur = (totalSum / tecajNumber).toFixed(2);

  const eurServices = services.map((service) => {
    return {
      ...service,
      eurPrice: (service.basePrice / tecajNumber).toFixed(2),
      eurTotalPrice: ((service.basePrice / tecajNumber) * (service.kolicina || 1)).toFixed(2),
    };
  });

  return (
    <PDFViewer width="100%" height="1200px" showToolbar={true}>
      <Render
        brojRacuna={brojRacuna}
        datum={datum}
        client={client}
        services={eurServices}
        tecaj={tecaj}
        totalSum={totalSum}
        totalSumEur={totalSumEur}
      />
    </PDFViewer>
  );
};
