interface InputProps {
  label: string;
  labelPlacement?: 'left' | 'top';
  isReadOnly?: boolean;
  value: string | number;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  type?: 'text' | 'number';
  width?: string;
  children?: React.ReactNode;
}

export const Input = ({
  label,
  labelPlacement,
  isReadOnly,
  value,
  type,
  width,
  onChange,
  onBlur,
  children,
}: InputProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    !!onChange && onChange(e.target.value);
  };
  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    !!onBlur && onBlur(e.target.value);
  };

  const Style = {
    width: width,
  };

  return (
    <div className={labelPlacement === 'top' ? 'oInput' : 'cInput'}>
      <label>{label}</label>
      <input
        readOnly={isReadOnly}
        type={type || 'text'}
        value={value}
        style={width ? Style : undefined}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
      {children}
    </div>
  );
};
