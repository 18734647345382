import { SyntheticEvent, useEffect, useState } from 'react';
import { format, isValid } from 'date-fns';

import { Service } from './types';
import clients from './db/clients';
import servicesDb from './db/services';
import { Input } from './components/Input';
import { PdfRenderer } from './components/PdfRenderer';
import SelectTecajModal from './components/SelectTecajModal';

import useExchange, { RateItem } from './services/useExchange';
import { createOurDate } from './utils';
import remoteLogo from './assets/remoteLogo.png';
import './App.css';

function App() {
  const [client, setClient] = useState(clients[0].id);

  const [brojRacuna, setBrojRacuna] = useState('1-1-1');
  const [silePareDatum, setSilePareDatum] = useState(format(new Date(), 'dd.MM.yyyy.'));
  const [datum, setDatum] = useState('');
  // const [datumTouched, setDatumTouched] = useState(false);
  const [tecaj, setTecaj] = useState('1.00');
  const [currency, setCurrency] = useState('EUR');
  const [services, setServices] = useState<Service[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isLoading, getBestRate } = useExchange();

  const selectClient = (e: SyntheticEvent) => {
    setClient(Number((e.target as HTMLSelectElement).value));
  };

  const selectTecaj = (item: RateItem) => {
    setTecaj(item.rate);
    setDatum(format(new Date(item.date), 'dd.MM.yyyy.'));
  };

  const selectedClient = clients.find((c) => c.id === client) || clients[0];

  useEffect(() => {
    const defaultService = servicesDb.find((s) => s.id === selectedClient.defaultServiceId);
    if (defaultService) {
      defaultService.kolicina = 1;
    }
    setServices(defaultService ? [defaultService] : []);
    setCurrency(selectedClient.currency);
  }, [selectedClient.defaultServiceId, selectedClient.currency]);

  // useEffect(() => {
  //   if (isValid(createOurDate(silePareDatum))) {
  //     const bestRate = getBestRate(silePareDatum, 16);
  //     if (bestRate) {
  //       if (!datumTouched) {
  //         setDatum(format(new Date(bestRate.date), 'dd.MM.yyyy.'));
  //       }

  //       setTecaj(bestRate.rate);
  //     }
  //   }
  // }, [silePareDatum, getBestRate, datumTouched]);

  const removeService = (index: number) => {
    const newArray = [...services];
    newArray.splice(index, 1);
    setServices(newArray);
  };

  const addService = () => {
    setServices([
      ...services,
      {
        id: 0,
        name: 'Nesto',
        serviceType: 'usl',
        basePrice: 1,
        kolicina: 1,
      },
    ]);
  };

  const handleServiceChange = (index: number, prop: keyof Service, value: unknown) => {
    const newArray = [...services];
    // @ts-ignore
    newArray[index][prop] = value;
    setServices(newArray);
  };

  const handleSetSilePareDatum = (value: string) => {
    setSilePareDatum(value);
    if (isValid(createOurDate(value))) {
      const bestRate = getBestRate(value, 16);
      console.log('bestRate', bestRate);
      if (bestRate) {
        setDatum(format(new Date(bestRate.date), 'dd.MM.yyyy.'));
        setTecaj(bestRate.rate);
      }
    } else {
      console.log('invalid date');
      setTecaj('1.00');
      setDatum('');
    }
  };

  const handleDatumChange = (value: string) => {
    setDatum(value);
    // setDatumTouched(true);
  };

  return (
    <div>
      <header>
        <img src={remoteLogo} className="logo" alt="Remote logo" />
      </header>

      <div>
        <div className="flex space-between">
          <div>
            <div className="cInput">
              <label>Klijent</label>
              <select onChange={selectClient}>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <Input label="Valuta" value={currency} onChange={setCurrency} />
          </div>
          <div>
            <Input label="Sile pare" value={silePareDatum} onChange={handleSetSilePareDatum}>
              <button className="ml-8" onClick={() => setIsModalOpen(true)}>
                Odaberi tecaj
              </button>
            </Input>
            <Input label="Najbolji tecaj" isReadOnly value={tecaj} onChange={setTecaj} />
            <Input label="Datum računa" value={datum} onChange={handleDatumChange} />
          </div>
          <div>
            <Input label="Broj računa" value={brojRacuna} onChange={setBrojRacuna} />
          </div>
        </div>

        <hr />

        <div>
          {services.map((service, index) => (
            <div className="flex" key={service.id}>
              <div className="table-item">
                <button className="danger" onClick={() => removeService(index)}>
                  Obrisi
                </button>
              </div>
              <div className="table-item">{index + 1}</div>
              <Input
                label="Opis proizvoda/usluge"
                labelPlacement="top"
                width="420px"
                value={service.name}
                onChange={(value) => handleServiceChange(index, 'name', value)}
              />
              <Input
                label="Jedinica"
                labelPlacement="top"
                width="60px"
                value={service.serviceType}
                onChange={(value) => handleServiceChange(index, 'serviceType', value)}
              />
              <Input
                label="Kolicina"
                labelPlacement="top"
                type="number"
                width="60px"
                value={service.kolicina || 1}
                onChange={(value) => handleServiceChange(index, 'kolicina', value)}
              />
              <Input
                label="Cijena"
                labelPlacement="top"
                type="number"
                value={service.basePrice}
                onChange={(value) => handleServiceChange(index, 'basePrice', value)}
              />
              <div className="table-item">
                {+service.basePrice * (service.kolicina || 1)} {currency}
              </div>
            </div>
          ))}
        </div>
        <div className="flex py-8">
          <button onClick={addService}>+ Dodaj jos nesto</button>
        </div>

        <hr />

        <PdfRenderer
          isLoading={isLoading}
          datum={datum}
          tecaj={tecaj}
          services={services}
          client={selectedClient}
          brojRacuna={brojRacuna}
        />
      </div>
      <SelectTecajModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSelect={selectTecaj}
        currentlySelectedDate={datum}
        silePareDatum={silePareDatum}
      />
    </div>
  );
}

export default App;
