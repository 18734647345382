import { StyleSheet } from '@react-pdf/renderer';

const lightGray = '#e0e0e0';
const gray = '#939393';

export const RenderStyles = StyleSheet.create({
  page: {
    padding: '40px',
    fontFamily: 'Helvetica',
    fontSize: '11px',
  },
  image: {
    height: '40px',
    width: '158px',
  },
  brojRacuna: {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  justifyBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  gray: {
    color: gray,
  },
  label: {
    fontSize: '10px',
    color: gray,
  },
  bold: {
    fontWeight: 700,
  },
  mt28: {
    marginTop: '28px',
  },
  borderBottom: {
    borderBottom: `1px solid ${lightGray}`,
  },
  companyName: {
    fontSize: '12px',
    fontWeight: 700,
    paddingVertical: '4px',
  },
  buyer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${lightGray}`,
    borderRadius: '4px',
    padding: '8px',
    width: '45%',
  },
  tableHeader: {
    color: '#FFFFFF',
    backgroundColor: gray,
    paddingVertical: '8px',
    paddingHorizontal: '4px',
    fontSize: '11px',
    fontWeight: 700,
  },
  tableRow: {
    paddingVertical: '8px',
    paddingHorizontal: '4px',
    fontSize: '11px',
    borderBottom: `1px solid ${lightGray}`,
  },
  tableUsdLine: {
    paddingTop: '8px',
    paddingBottom: '2px',
    paddingHorizontal: '4px',
    fontSize: '11px',
  },
  tableEurLine: {
    paddingTop: '0px',
    paddingBottom: '8px',
    paddingHorizontal: '4px',
    fontSize: '10px',
  },
  tableTotal: {
    paddingTop: '18px',
    paddingHorizontal: '4px',
    fontSize: '11px',
  },
  footer: {
    position: 'absolute',
    left: '40px',
    bottom: '40px',
    width: '100%',
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  footerColumns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '12px',
  },
});
