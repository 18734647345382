import { format, addDays } from 'date-fns';

const createDate = (date: string) => {
  const dateArray = date.split('.');
  return new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0]);
};

export const formatDatum = (dateString: string) => {
  const date = createDate(dateString);
  return format(date, 'dd.MM.yyyy.');
};

export const add30Days = (dateString: string) => {
  const date = createDate(dateString);
  const newDate = addDays(date, 30);
  return format(newDate, 'dd.MM.yyyy.');
};

export const createOurDate = (dateString: string) => {
  const [day, month, year] = dateString.split('.');
  const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  return date;
};
