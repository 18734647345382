import wretch from 'wretch';
import { useQuery } from '@tanstack/react-query';
import { subDays, endOfDay } from 'date-fns';

import { createOurDate } from '../utils';

const ECB_URL = 'https://remote.hr/api/proxy/ecb';

export type RateItem = {
  date: string;
  rate: string;
};

const getExchangeRates = () => {
  return wretch(ECB_URL).get().json<RateItem[]>();
};

function useExchange() {
  const {
    isLoading,
    error,
    data: rates,
  } = useQuery({
    queryKey: ['exchange'],
    queryFn: getExchangeRates,
  });

  const getRatesInRange = (datum: string, days: number) => {
    if (isLoading || error) {
      return [];
    }

    const endDate = createOurDate(datum);
    const startDate = subDays(endDate, days);
    const startTs = startDate.getTime();
    const endTs = endOfDay(endDate).getTime();
    console.log(startDate, endDate);

    const options = [];
    for (const item of rates || []) {
      const ts = new Date(item.date).getTime();
      if (ts >= startTs && ts <= endTs) {
        options.push(item);
      }
    }
    return options;
  };

  const getBestRate = (datum: string, days: number) => {
    const options = getRatesInRange(datum, days);
    if (options.length === 0) {
      return undefined;
    }
    const best = options.reduce(
      (acc, item) => {
        if (parseFloat(item.rate) > parseFloat(acc.rate)) {
          return item;
        }
        return acc;
      },
      { rate: '0', date: '' },
    );
    return best;
  };

  return {
    isLoading,
    error,
    rates,
    getRatesInRange,
    getBestRate,
  };
}

export default useExchange;
