import { Client } from '../types';

const clients = [
  {
    id: 1,
    name: 'MixMode',
    address1: '111 West Micheltorena Street, Ste. 300',
    address2: 'Santa Barbara, CA 93101',
    address3: 'USA',
    defaultServiceId: 1,
    currency: 'USD',
  },
  {
    id: 2,
    name: 'Deel Inc.',
    address1: '425 1st St.',
    address2: 'San Francisco, CA 94105',
    address3: 'USA',
    defaultServiceId: 2,
    currency: 'USD',
  },
  {
    id: 3,
    name: 'Generic EUR',
    address1: 'Obala Gusarskih Brigada 12',
    address2: 'Omis, 21310',
    address3: 'Hrvatska',
    defaultServiceId: undefined,
    currency: 'EUR',
  },
];

export default clients as Client[];
