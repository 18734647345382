import Modal from 'react-modal';
import clsx from 'clsx';
import { format } from 'date-fns';

import useExchange, { RateItem } from '../services/useExchange';
import './SelectTecajModal.styles.css';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

type SelectTecajModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (rate: RateItem) => void;
  currentlySelectedDate: string;
  silePareDatum: string;
};

const BROJ_DANA_UNAZAD = 30;

const SelectTecajModal = ({
  isOpen,
  onClose,
  currentlySelectedDate,
  onSelect,
  silePareDatum,
}: SelectTecajModalProps) => {
  const { getRatesInRange, getBestRate } = useExchange();
  const data = getRatesInRange(silePareDatum, BROJ_DANA_UNAZAD);
  const bestRate = getBestRate(silePareDatum, BROJ_DANA_UNAZAD);

  const onItemClick = (item: RateItem) => {
    onSelect(item);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyle}
      contentLabel="Odabir tecaja"
      overlayClassName="ModalOverlay"
    >
      <h3>Odaberi tecaj</h3>
      <table className="SelectModal">
        <tbody>
          {data?.map((item) => (
            <tr
              key={item.date}
              className={clsx({
                'best-rate': bestRate?.date === item.date,
                'selected-rate': currentlySelectedDate === format(new Date(item.date), 'dd.MM.yyyy.'),
              })}
              onClick={() => onItemClick(item)}
            >
              <td>{item.date}</td>
              <td>{item.rate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

export default SelectTecajModal;
